<template>
  <div class="u-files-up w-100 pt-5">
    <b-row class="mx-0">
      <div class="d-flex justify-content-between align-items-center w-100 px-3 pt-5">
        <div
          class="request-item d-flex flex-column justify-content-between align-items-center rounded py-4 px-2"
        >
          <div class="d-flex justify-content-center align-items-center mb-3">
            <img svg-inline :src="url" alt="Uello" :class="type" />
            <p class="h5 icon-lable ml-3 font-weight-bold text-transition mb-0">
              {{ this.$t('requestItems.pickupTextbox') }}
            </p>
          </div>
          <div class="d-flex justify-content-center align-items-center mb-3 w-25">
            <p class="h6 m-0 text-center font-weight-bold p-2">
              <span
                class="text-primary m-0 text-center px-2"
                v-html="$t('requestItems.pickupObs')"
              ></span>
            </p>
          </div>
          <div class="d-flex justify-content-center align-items-center mb-3 w-25" v-if="hasPickup">
            <p class="h6 m-0 text-center font-weight-bold p-2">
              <span class="text-transition m-0 text-center px-2">
                {{ $t('requestItems.nextPickup') }} {{ dateScheduled.date }}
              </span>
            </p>
          </div>
          <div
            class="d-flex flex-column align-items-center w-25"
            @click.prevent="() => {}"
            v-if="!hasPickup"
          >
            <uello-button
              color="red"
              class="mt-4 px-3 w-100 mx-auto"
              @click="getDate"
              :disabled="loading"
            >
              <span v-if="loading">
                <b-spinner small label="Loading..."></b-spinner>&nbsp;{{
                  $t('agGrid.localeText.loadingOoo')
                }}
              </span>
              <span v-else>{{ $t('requestItems.pickupRequest') }}</span>
            </uello-button>
            <b-modal
              centered
              hide-footer
              id="modal-confirm"
              ref="modal-confirm"
              size="m"
              :title="$t('requestItems.pickupSchedule')"
              :header-bg-variant="headerBgVariant"
              :header-text-variant="headerTextVariant"
            >
              <div class="d-flex justify-content-center align-items-center w-100 px-3">
                <div class="d-flex flex-column justify-content-center align-items-center py-4 px-3">
                  <div class="d-flex justify-content-center align-items-center">
                    <p class="h5 icon-lable ml-3 font-weight-bold text-transition mb-0">
                      <small class="text-muted">{{ $t('requestItems.pickupConfirmation') }}</small>
                    </p>
                  </div>
                  <div class="d-flex justify-content-center align-items-center">
                    <p class="h5 icon-lable ml-3 text-uppercase font-weight-bold mb-0">
                      {{ dateScheduled.dayOfWeek }}
                    </p>
                  </div>
                  <div class="d-flex justify-content-center align-items-center">
                    <p class="h5 icon-lable ml-3 mb-0">
                      {{ dateScheduled.date }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <uello-button color="red" @click="createPickup" size="small">
                  {{ $t('requestItems.confirm') }}
                </uello-button>
              </div>
            </b-modal>
          </div>
          <div class="d-flex flex-column align-items-center w-25" @click.prevent="() => {}" v-else>
            <uello-button
              color="red"
              class="mt-4 px-3 w-100 mx-auto"
              :disabled="loading"
              @click="showPickupModal"
            >
              <span v-if="loading">
                <b-spinner small label="Loading..."></b-spinner>&nbsp;{{
                  $t('agGrid.localeText.loadingOoo')
                }}
              </span>
              <span v-else>{{ $t('requestItems.pickupCancel') }}</span>
            </uello-button>
            <b-modal
              centered
              hide-footer
              id="modal-cancel"
              ref="modal-cancel"
              size="m"
              :title="$t('requestItems.pickupSchedule')"
              :header-bg-variant="headerBgVariant"
              :header-text-variant="headerTextVariant"
            >
              <div class="d-flex justify-content-center align-items-center w-100 px-3">
                <div class="d-flex flex-column justify-content-center align-items-center py-4 px-3">
                  <div class="d-flex justify-content-center align-items-center">
                    <p class="h5 icon-lable ml-3 font-weight-bold text-transition mb-0">
                      <small class="text-muted">{{ $t('requestItems.cancelPickup') }}</small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <uello-button @click="cancelPickup" size="small" color="red">
                  {{ $t('requestItems.confirm') }}
                </uello-button>
              </div>
            </b-modal>
          </div>
          <div class="d-flex justify-content-center align-items-center mt-3 w-25">
            <p class="h6 m-0 text-center font-weight-bold">
              <span class="text-transition m-0 text-center px-2 pre">
                {{ scheduleText }}
              </span>
            </p>
          </div>
          <div class="d-flex flex-column align-items-center w-25">
            <uello-button
              color="red"
              class="mt-4 px-3 w-100 mx-auto"
              v-on:click="showPickupSchedule"
              :disabled="loading"
            >
              <span v-if="loading">
                <b-spinner small label="Loading..."></b-spinner>&nbsp;{{
                  $t('agGrid.localeText.loadingOoo')
                }}
              </span>
              <span v-else>{{ $t('requestItems.recurrentSchedule') }}</span>
            </uello-button>
            <b-modal
              centered
              hide-footer
              id="modal-schedule"
              ref="modal-schedule"
              size="m"
              :title="$t('requestItems.recurrentSchedule')"
              :header-bg-variant="headerBgVariant"
              :header-text-variant="headerTextVariant"
            >
              <div class="d-flex justify-content-center align-items-center w-100 px-3">
                <div class="d-flex flex-column justify-content-center align-items-center py-4 px-3">
                  <div class="d-flex justify-content-center align-items-center">
                    <p class="h5 icon-lable ml-3 font-weight-bold text-transition mb-0">
                      <small class="text-muted">{{
                        $t('requestItems.pickupScheduleSelect')
                      }}</small>
                    </p>
                  </div>
                  <div class="d-flex mt-3 justify-content-center align-items-center">
                    <b-button-group size="sm">
                      <b-button
                        class="roundedButton mx-1"
                        v-for="(btn, idx) in scheduleWeek"
                        :key="idx"
                        :pressed.sync="btn.state"
                        variant="secondary"
                      >
                        {{ btn.caption }}
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <uello-button @click="setScheduledWeek" size="small" color="red">
                  {{ $t('requestItems.confirm') }}
                </uello-button>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </b-row>
    <b-modal
      centered
      hide-footer
      id="modal-scheduled"
      ref="modal-scheduled"
      size="m"
      :title="$t('requestItems.pickupSchedule')"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
    >
      <div class="d-flex justify-content-center align-items-center w-100 px-3">
        <div class="d-flex flex-column justify-content-center align-items-center py-4 px-3">
          <div class="d-flex justify-content-center align-items-center">
            <p class="h5 icon-lable ml-3 font-weight-bold text-transition mb-0">
              <small class="text-muted">{{ $t('requestItems.pickupScheduled') }}</small>
            </p>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <p class="h5 icon-lable ml-3 text-uppercase font-weight-bold mb-0">
              {{ dateScheduled.dayOfWeek }}
            </p>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <p class="h5 icon-lable ml-3 mb-0">
              {{ dateScheduled.date }}
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <uello-button
          color="red"
          @click="$bvModal.hide('modal-scheduled')"
          size="small"
          text="OK"
        />
      </div>
    </b-modal>
    <b-modal
      centered
      hide-footer
      id="modal-cancel-confirm"
      ref="modal-cancel-confirm"
      size="m"
      :title="$t('requestItems.pickupCancel')"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
    >
      <div class="d-flex justify-content-center align-items-center w-100 px-3">
        <div class="d-flex flex-column justify-content-center align-items-center py-4 px-3">
          <div class="d-flex justify-content-center align-items-center">
            <p class="h5 icon-lable ml-3 font-weight-bold text-transition mb-0">
              <small class="text-muted">{{ $t('requestItems.cancelPickupConfirmation') }}</small>
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <uello-button
          @click="$bvModal.hide('modal-cancel-confirm')"
          color="small"
          size="small"
          text="OK"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { UelloButton } from '@uello/componentello';
import api from '@/services/api';
import { centralWindowSize } from '@/mixins';

export default {
  name: 'u-pickup',
  mixins: [centralWindowSize],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    UelloButton,
  },
  data() {
    return {
      dateScheduled: {},
      headerBgVariant: 'primary',
      headerTextVariant: 'light',
      hasPickup: false,
      loading: false,
      reCheckTimer: null,
      scheduledWeek: [false, false, false, false, false, false, false],
      week: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      scheduleWeek: [
        { caption: 'D', state: false },
        { caption: 'S', state: false },
        { caption: 'T', state: false },
        { caption: 'Q', state: false },
        { caption: 'Q', state: false },
        { caption: 'S', state: false },
        { caption: 'S', state: false },
      ],
    };
  },
  mounted() {
    this.checkPickupDates();
    this.getScheduledWeek();
  },
  computed: {
    url() {
      // eslint-disable-next-line
      const image = require(`@/assets/images/icon-form-textbox-gray.svg`);

      return image;
    },
    scheduleText() {
      const self = this;
      if (
        this.scheduledWeek.filter(value => {
          return value;
        }).length > 0
      ) {
        const firstText = this.$t('requestItems.textScheduledPickup');
        let days = '';

        this.scheduledWeek.forEach((value, index) => {
          if (value) {
            const formattedDay = self.$t(`general.${self.week[index]}`);
            days += `${formattedDay}, `;
          }
        });

        days = days.replace(/,\s$/i, '');

        return `${firstText}\t\n${days}`;
      }

      return this.$t('requestItems.nonePickup');
    },
  },
  watch: {},
  methods: {
    async checkPickupDates() {
      this.loading = true;
      const { data: response } = await api.get(`/order/pickup`);

      if (!this.isEmpty(response.data)) {
        this.dateScheduled = response.data;
        this.hasPickup = true;
      } else {
        this.hasPickup = false;

        if (this.reCheckTimer != null) {
          clearTimeout(this.reCheckTimer);
          this.reCheckTimer = null;
        }
      }

      this.loading = false;
    },
    async getDate() {
      if (this.hasPickup) {
        this.$refs['modal-scheduled'].show();
        return;
      }

      const { data: response } = await api.get(`/order/pickup/date`);

      this.dateScheduled = response.data;
      this.$refs['modal-confirm'].show();
    },
    async createPickup() {
      const { data: response } = await api.post(`/order/pickup`, {
        date: this.dateScheduled.date,
      });

      this.$bvModal.hide('modal-confirm');

      if (response.success) {
        this.$bvModal.show('modal-scheduled');
        this.hasPickup = true;
      }
    },
    showPickupModal() {
      this.$bvModal.show('modal-cancel');
    },
    showPickupSchedule() {
      this.$bvModal.show('modal-schedule');
    },
    async cancelPickup() {
      const { data: response } = await api.post(`/order/pickup/cancel`);

      this.$bvModal.hide('modal-cancel');

      if (response.success) {
        this.$bvModal.show('modal-cancel-confirm');
      }

      this.reCheckTimer = setInterval(this.checkPickupDates, 5000);
    },
    async getScheduledWeek() {
      const self = this;
      this.loading = true;
      const { data: response } = await api.get(`/order/pickup/schedule`);

      if (response.success) {
        this.scheduledWeek = response.data;

        this.scheduledWeek.forEach((value, index) => {
          if (value) {
            self.scheduleWeek[index].state = true;
          }
        });
      }
      this.loading = false;
    },
    async setScheduledWeek() {
      const newWeek = [];

      this.scheduleWeek.forEach(value => {
        newWeek.push(value.state);
      });

      const { data: response } = await api.post(`/order/pickup/schedule`, {
        week: newWeek,
      });

      if (response.success) {
        this.$bvModal.hide('modal-schedule');
        this.getScheduledWeek();
        this.$toast.success(this.$t('messages.schedulePickupCreated'));
      }
    },
    isEmpty(value) {
      return (
        value &&
        ((value.constructor === Object && Object.keys(value).length === 0) ||
          (value.constructor === Array && value.length === 0))
      );
    },
  },
};
</script>

<style lang="scss">
$vs-state-active-bg: var(--primary) !default;

.border-light {
  border: 2px solid var(--lighen);
}

.active {
  background: #e63b4f !important;
  border-color: #e63b4f !important;
}

.roundedButton {
  border-radius: 50% !important;
  width: 35px;
  height: 35px;
}

.pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}

.u-files-up {
  img.form-textbox {
    width: 50px;
  }

  .have-operations {
    width: 352.38px;
  }

  .vs__dropdown-menu li {
    color: var(--transition);
    font-weight: bold;

    &:hover,
    &:active,
    &.vs__dropdown-option--highlight {
      color: #fff;
      background: var(--primary);
    }
  }

  .vs__search,
  .vs__search:focus {
    color: var(--transition);
    font-weight: bold;
  }

  .btn.btn-outline-secondary {
    &:hover {
      svg {
        fill: #fff !important;
      }
    }
  }

  .vs__dropdown-toggle {
    border: 2px solid var(--light);
    width: 352.39px;
    height: 44px;
  }

  .request-message {
    border: 2px solid var(--light);
  }

  .dropzone-custom-content,
  .dz-message {
    display: block !important;
    font-family: 'Open Sans', sans-serif !important;
  }

  .dz-preview {
    display: none !important;
  }

  .dz-default.dz-message {
    display: block !important;
  }

  .process-status {
    small {
      font-size: 10px;
    }
  }
}
</style>
